import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { token } from '@atlaskit/tokens';
import { B300, N0, N90 } from '@atlaskit/theme/colors';
import CheckboxIcon from '@atlaskit/icon/glyph/checkbox';
import { MediaImage } from '@atlaskit/media-image';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { MediaClientProvider, useFileState } from '@atlaskit/media-client-react';
import { Flex, xcss } from '@atlaskit/primitives';

import { createEditorMediaProvider } from '@confluence/fabric-media-support';

import { useIsDisabledContext } from './IsDisabledContext';
import { LoadingDiv } from './LoadingDiv';

const i18n = defineMessages({
	checkmarkLabel: {
		id: 'custom-sites-extensions.shared-components.cover-image-area.checkmark-label',
		defaultMessage: 'The current image is selected',
		description: 'Checkmark label for the checkmark icon displayed only when an image is selected',
	},
	imageFailedMessage: {
		id: 'custom-sites-extensions.shared-components.image-failed-to-load',
		defaultMessage: 'Image failed to load',
		description: "Message shown when the extensions' uploaded image failed to load",
	},
});

export const UNSPLASH_URL = 'images.unsplash.com';

const FILENAME_LIMIT = 100;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.div<{
	shouldUseCompactStyles: boolean;
	isDisabled?: boolean;
}>({
	width: '100%',
	display: 'flex',
	background: token('elevation.surface', N0),
	columnGap: token('space.100', '10px'),
	borderRadius: token('border.radius.100', '4px'),
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ shouldUseCompactStyles }) =>
		shouldUseCompactStyles ? token('space.400', '32px') : token('space.600', '48px'),
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	boxShadow: ({ isDisabled }) =>
		`inset 0 0 0 2px ${
			isDisabled ? token('color.border.disabled') : token('color.border.selected', B300)
		}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageContentWrapper = styled.div({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	columnGap: token('space.100', '10px'),
	paddingLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	marginBottom: token('space.025', '2px'),
	marginLeft: token('space.negative.025', '-2px'),
	color: token('color.text.subtlest', N90),
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CheckmarkWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		position: 'absolute',
		bottom: token('space.negative.100', '-8px'),
		right: token('space.negative.100', '-8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ImageWrapper = styled.img<{ shouldUseCompactStyles: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ shouldUseCompactStyles }) =>
		shouldUseCompactStyles ? token('space.300', '24px') : token('space.500', '40px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ shouldUseCompactStyles }) =>
		shouldUseCompactStyles ? token('space.300', '24px') : token('space.500', '40px'),
	borderRadius: token('border.radius.100', '3px'),
	objectFit: 'cover',
	objectPosition: 'center',
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FileName = styled(Text)<{
	shouldUseCompactStyles: boolean;
	isDisabled?: boolean;
}>({
	display: '-webkit-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	WebkitLineClamp: ({ shouldUseCompactStyles }) => (shouldUseCompactStyles ? 1 : 2),
	WebkitBoxOrient: 'vertical',
	overflowWrap: 'anywhere',
	overflow: 'hidden',
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginLeft: ({ shouldUseCompactStyles }) => (shouldUseCompactStyles ? token('space.075') : 0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isDisabled }) => isDisabled && { color: token('color.text.disabled') },
});

type ImageSelectorProps = {
	contentId: string;
	selectedImageId?: string;
	isExternalShare: boolean;
	shouldUseCompactStyles?: boolean;
	shouldShowErrorMessage?: boolean;
};

const truncateFilename = (filename: string): string => {
	if (!filename) {
		return '';
	}
	const filenameParts = filename.split('.');
	const name = filenameParts[0];
	const extension = filenameParts[1];

	if (name.length > FILENAME_LIMIT) {
		return `${name.substring(0, 95)}...${name.substring(name.length - 5)}.${extension}`;
	} else {
		return filename;
	}
};

export const ImageSelectedComponent = ({
	selectedImageId,
	contentId,
	isExternalShare,
	shouldUseCompactStyles,
	shouldShowErrorMessage,
}: ImageSelectorProps) => {
	const [mediaConfig, setMediaConfig] = useState<{
		collection: string | undefined;
		authProvider: MediaClientConfig['authProvider'];
	} | null>(null);

	// Setup media config
	useEffect(() => {
		const createMediaConfig = async () => {
			const { uploadParams, uploadMediaClientConfig } = await createEditorMediaProvider({
				contentId,
				contentType: 'page',
				isExternalShare,
			});

			if (uploadMediaClientConfig == undefined) {
				return null;
			}
			setMediaConfig({
				collection: uploadParams?.collection,
				authProvider: uploadMediaClientConfig.authProvider,
			});
		};

		void createMediaConfig();
	}, [contentId, isExternalShare]);

	if (!mediaConfig) {
		return null;
	}
	return (
		<MediaClientProvider clientConfig={mediaConfig}>
			<ImageIcon
				contentId={contentId}
				mediaConfig={mediaConfig}
				selectedImageId={selectedImageId}
				shouldUseCompactStyles={shouldUseCompactStyles}
				shouldShowErrorMessage={shouldShowErrorMessage}
			/>
		</MediaClientProvider>
	);
};

const errorWrapperStyles = xcss({
	alignItems: 'center',
	marginTop: 'space.050',
});

const ImageIcon = ({
	selectedImageId,
	contentId,
	mediaConfig,
	shouldUseCompactStyles,
	shouldShowErrorMessage,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const intl = useIntl();
	const isDisabled = useIsDisabledContext();
	const [isImageError, setIsImageError] = useState(false);

	const { fileState } = useFileState(selectedImageId, {
		collectionName: contentId,
		skipRemote: !selectedImageId,
	});

	return (
		<>
			{isImageError ? (
				<Flex xcss={errorWrapperStyles}>
					<ErrorIcon label="" size="small" primaryColor={token('color.icon.danger')} />
					<FormattedMessage {...i18n.imageFailedMessage} />
				</Flex>
			) : (
				<Tooltip
					content={isDisabled ? undefined : truncateFilename((fileState as any)?.name)}
					position="top"
				>
					<Image
						data-testid="image-selected"
						shouldUseCompactStyles={shouldUseCompactStyles}
						isDisabled={isDisabled}
						role="presentation"
					>
						<ImageContentWrapper>
							<MediaImage
								identifier={{
									mediaItemType: 'file',
									id: String(selectedImageId),
									collectionName: mediaConfig.collection,
								}}
								//force rerender image on id change until https://product-fabric.atlassian.net/browse/CXP-2899 is fixed
								key={selectedImageId}
								mediaClientConfig={{
									authProvider: mediaConfig.authProvider,
								}}
								apiConfig={{
									allowAnimated: true,
									mode: 'full-fit' as const,
									upscale: false,
								}}
							>
								{({ data, loading, error }) => {
									if (loading || isDisabled) {
										return <LoadingDiv shouldUseCompactStyles={shouldUseCompactStyles} />;
									}
									if (!data) {
										return null;
									}
									if (error) {
										if (shouldShowErrorMessage) {
											setIsImageError(true);
										}
										createAnalyticsEvent({
											type: 'error',
											data: {
												actionSubject: 'image',
												actionSubjectId: 'coverImageSelected',
												source: 'coverImageSelectedComponent',
											},
										}).fire();
									}
									return (
										<ImageWrapper
											shouldUseCompactStyles={shouldUseCompactStyles}
											src={data.src}
											alt=""
											id="selected-image"
										/>
									);
								}}
							</MediaImage>
							<FileName shouldUseCompactStyles={shouldUseCompactStyles} isDisabled={isDisabled}>
								{(fileState as any)?.name}
							</FileName>
						</ImageContentWrapper>
						<CheckmarkWrapper>
							<CheckboxIcon
								size="large"
								label={intl.formatMessage(i18n.checkmarkLabel)}
								primaryColor={isDisabled ? token('color.icon.disabled') : token('color.icon.brand')}
							/>
						</CheckmarkWrapper>
					</Image>
				</Tooltip>
			)}
		</>
	);
};

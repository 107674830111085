import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const compactSizeStyles = xcss({
	width: '24px',
	height: '24px',
});

const baseStyles = xcss({
	width: '40px',
	height: '40px',
	borderRadius: 'border.radius.100',
	backgroundColor: 'color.background.neutral',
	flexShrink: 0,
});

export const LoadingDiv = ({ shouldUseCompactStyles }: { shouldUseCompactStyles?: boolean }) => (
	<Box xcss={[baseStyles, shouldUseCompactStyles && compactSizeStyles]} />
);

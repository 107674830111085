import { UNSPLASH_URL } from '../../../shared-components/ImageSelectedComponent';
import type { MediaConfigType } from '../../linkCardsTypes';

type getImageTypeToShowProps = {
	imageSrc?: string;
	mediaConfig?: MediaConfigType;
};

export const getImageTypeToShow = ({ imageSrc, mediaConfig }: getImageTypeToShowProps) => {
	const showUnsplashImage = imageSrc?.includes(UNSPLASH_URL);
	const showCustomUploadedImage = mediaConfig && !!imageSrc && !imageSrc?.includes(UNSPLASH_URL);

	return {
		showUnsplashImage,
		showCustomUploadedImage,
	};
};
